import { prestashop, atob as base64Decode, btoa as base64Encode } from 'window';
import { helpers } from 'App';

/**
 * Test if the given string is an object encoded in base64
 *
 * @param  {String}  string The string to test
 * @return {Boolean}        Is it an encoded object?
 */
function isBase64Object(string) {
  try {
    JSON.parse(base64Decode(string));
  } catch (err) {
    if (err) {
      return false;
    }
  }

  return true;
}

/**
 * Encode an object in base64
 *
 * @param  {Object} object The object to encode
 * @return {String}        The encoded object
 */
function encodeObjectInBase64(object) {
  return Object.keys(object).length ? base64Encode(JSON.stringify(object)) : '';
}

/**
 * Decode an object from its base64 encoding
 *
 * @param  {String} string The base6 string to decode
 * @return {Obect}         The decoded and parsed object
 */
function decodeObjectInBase64(string) {
  return JSON.parse(base64Decode(string));
}

/**
 * Alter an image URL to work with the CDN resizer
 * @param  {String} src    The source of the image
 * @param  {Object} params The parameters of the modifications
 * @return {String}        A formatted source
 */
function resize(src, params = {}) {
  if (!prestashop.modules.meta_frontsettings.CONFIG.PROTOCOL || !prestashop.modules.meta_frontsettings.CONFIG.PS_MEDIA_SERVER) {
    return src;
  }

  const parsedSrc = helpers.parseUrl(src);
  const srcPathParts = parsedSrc.pathname.substring(1).split('/');
  const [ fileName, potentialParams, ...otherParts ] = [
    ...srcPathParts,
  ].reverse();
  const newSrc = helpers.parseUrl(
    `${prestashop.modules.meta_frontsettings.CONFIG.PROTOCOL}${prestashop.modules.meta_frontsettings.CONFIG.PS_MEDIA_SERVER}`,
  );

  let newSrcPathParts;
  // Merge old params and new ones if params are alreay defined in the url
  if (isBase64Object(potentialParams)) {
    const oldParams = decodeObjectInBase64(potentialParams);
    const newParams = encodeObjectInBase64({ ...oldParams, ...params });
    newSrcPathParts = [ fileName, newParams, ...otherParts ].reverse();
  } else {
    const newParams = encodeObjectInBase64(params);
    newSrcPathParts = [
      fileName,
      newParams,
      potentialParams,
      ...otherParts,
    ].reverse();
  }

  // Filter out empty parts
  newSrcPathParts = newSrcPathParts.filter((part) => part.length > 0);

  // Update new source pathname
  newSrc.pathname = `/${newSrcPathParts.join('/')}`;

  // And return its href
  return newSrc.href;
}

// Attach to helpers
helpers.resizeImage = resize;
